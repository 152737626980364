@font-face {
    font-family: "cy-custom-icons";
    src: url("./cy-custom-icons.eot?6047d69bc1380c64fac8b3c21ca6c783?#iefix") format("embedded-opentype"),
url("./cy-custom-icons.woff2?6047d69bc1380c64fac8b3c21ca6c783") format("woff2"),
url("./cy-custom-icons.woff?6047d69bc1380c64fac8b3c21ca6c783") format("woff"),
url("./cy-custom-icons.ttf?6047d69bc1380c64fac8b3c21ca6c783") format("truetype"),
url("./cy-custom-icons.svg?6047d69bc1380c64fac8b3c21ca6c783#cy-custom-icons") format("svg");
}

i.icon.circle-check,
i.icon.circle-pause,
i.icon.circle-progress,
i.icon.cy,
i.icon.exact,
i.icon.load,
i.icon.unload {
    font-family: cy-custom-icons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

i.icon.circle-check:before {
    content: "\f101";
}
i.icon.circle-pause:before {
    content: "\f102";
}
i.icon.circle-progress:before {
    content: "\f103";
}
i.icon.cy:before {
    content: "\f104";
}
i.icon.exact:before {
    content: "\f105";
}
i.icon.load:before {
    content: "\f106";
}
i.icon.unload:before {
    content: "\f107";
}
